// // import logo from './logo.svg';
// // import './App.css';
// // import React, { useEffect } from 'react';
// // import {
// //   BrowserRouter as Router,
// //   Switch,
// //   Route,
// //   Link,
// //   Redirect,
// //   useLocation
// // } from "react-router-dom";
// // import store from './redux/store';

// // import { getUser } from './redux/action';
// // import { Provider, useDispatch, useSelector } from 'react-redux';
// // import Header from './Path/Header';
// // import NotFound from './Path/NotFound';
// // import SideBar from './Path/SideBar';

// // import AllBooks from './Path/Admin/Book';
// // import NewBook from './Path/Admin/Book/BookAddNew';
// // import BookDetails from './Path/Admin/Book/BookDetails';
// // import { slugs } from './Constant/slugs';
// // import AllChapters from './Path/Admin/ChapterWiseExam';
// // import AllDailyExam from './Path/Admin/DailyExam';
// // import AllDailyResults from './Path/Admin/DailyResults';
// // import AllDemoExam from './Path/Admin/DemoExam';
// // import AllExam from './Path/Admin/Exam';
// // import AllResults from './Path/Admin/Results';
// // import ChaptersDetails from './Path/Admin/ChapterWiseExam/ChapterDetails';
// // import DailyExamDetails from './Path/Admin/DailyExam/DailyExamDetails';
// // import DailyResultDetails from './Path/Admin/DailyResults/ResultsDetails';
// // import DemoExamDetails from './Path/Admin/DemoExam/DemoExamDetails';
// // import ExamDetails from './Path/Admin/Exam/ExamDetails';
// // import ResultDetails from './Path/Admin/Results/ResultsDetails';
// // import NewChapter from './Path/Admin/ChapterWiseExam/ChapterAddNew';
// // import NewDailyExam from './Path/Admin/DailyExam/DailyExamAddNew';
// // import NewDemoExam from './Path/Admin/DemoExam/DemoExamAddNew';
// // import NewExam from './Path/Admin/Exam/ExamAddNew';
// // import AllStudents from './Path/Admin/Students';
// // import StudentDetails from './Path/Admin/Students/StudentDetails';
// // import NewStudent from './Path/Admin/Students/StudentAddNew';
// // import AllPaidExamList from './Path/Student/Exam';
// // import AllDemoExamList from './Path/Student/DemoExam';
// // import AllChpaterExamList from './Path/Student/ChapterWiseExam';
// // import AllDailyExamList from './Path/Student/DailyExam';
// // import LoginScreen from './Path/SignIn';
// // import StartExam from './Path/Student/StartExam';
// // import StartDemoExam from './Path/Student/StartDemoExam';
// // import ExamResult from './Path/Student/ExamResult';
// // import StartDailyExam from './Path/Student/StartDailyExam';
// // import DailyExamResult from './Path/Student/ExamResult/DailyExam';
// // import ExamAllResult from './Path/Student/AllResults';
// // import DailyExamAllResult from './Path/Student/AllResults/DailyResults';
// // import StartChapterExam from './Path/Student/ChapterWiseExam/StartExam';
// // import Profile from './Path/Profile';
// // import AllBatch from './Path/Admin/Batch';
// // import BatchDetails from './Path/Admin/Batch/BatchDetails';
// // import NewBatch from './Path/Admin/Batch/BatchAddNew';
// // import ExamResultDetailsAdmin from './Path/Admin/Results/ResultsDetails';
// // import ChapterExamAllResult from './Path/Student/AllResults/ChapterResults';
// // import ChapterExamResult from './Path/Student/ExamResult/ChapterWiseResult';
// // import AllChapterResults from './Path/Admin/ChapterResults';
// // import ChapterExamResultDetailsAdmin from './Path/Admin/ChapterResults/ResultsDetails';


// // export default class App extends React.Component {
// //   render() {
// //     return (
// //       <Provider store={store}>
// //         <Router>
// //           <AppMain />
// //         </Router>
// //       </Provider>
// //     );
// //   }
// // }

// // function AppMain() {
// //   const [loginState, setLoginState] = React.useState(true)
// //   const dispatch = useDispatch()
// //   const myState = useSelector((state) => state.LocationState);
// //   const Login = useSelector((state) => state.LoginState);
// //   const location = useLocation()

// //   const [menuExpand, setMenuExpand] = React.useState(false)
// //   useEffect(() => {
// //     dispatch(getUser())
// //     setLoginState(Login.isLoading)
// //   }, [])
// //   return (
// //     <div className="overflow-x-hidden" >
// //       {Login.token == null ?
// //         <LoginScreen />
// //         :
// //         <div className="row" >
// //           {!location.pathname.includes("start") &&
// //             <div className="sidebar-width-expand" style={{borderRight:"3px solid #e4e4e4"}} >
// //               <div className={"sidebar-design"}>
// //                 <div style={{backgroundColor:"#fff"}} >
// //                   <SideBar menuExpand={menuExpand} />
// //                 </div>
// //               </div>
// //             </div>
// //           }

// //           {menuExpand &&
// //             <div className="sidebar-small-screen ">
// //               <div className={"sidebar-design"} >
// //                 <SideBar setMenuExpand={setMenuExpand} />
// //               </div>
// //             </div>
// //           }

// //           <div className={!location.pathname.includes("start") ? "main-width-expand" : "main-width-full"} style={{ backgroundColor: "#f3f3f3" }}>
// //             <Header setMenuExpand={setMenuExpand} menuExpand={menuExpand} />
// //             <div>
// //               {Login.userRole == "author" &&
// //                 <Switch>
// //                   <Route exact path="/"><AllExam /></Route>

// //                   <Route exact path="/profile"><Profile /></Route>
// //                   <Route path={slugs.all_books}><AllBooks /></Route>
// //                   <Route path={slugs.all_chapters}><AllChapters /></Route>
// //                   <Route path={slugs.all_daily_exam}><AllDailyExam /></Route>
// //                   <Route path={slugs.all_daily_results}><AllDailyResults /></Route>
// //                   <Route path={slugs.all_demo_exam}><AllDemoExam /></Route>
// //                   <Route path={slugs.all_exam}><AllExam /></Route>
// //                   <Route path={slugs.all_results}><AllResults /></Route>
// //                   <Route path={slugs.all_chapter_results}><AllChapterResults /></Route>
// //                   <Route path={slugs.all_students}><AllStudents /></Route>
// //                   <Route path={slugs.all_batch}><AllBatch /></Route>

// //                   <Route path={`${slugs.books_details}:slug`}><BookDetails /></Route>
// //                   <Route path={`${slugs.chapter_details}:slug`}><ChaptersDetails /></Route>
// //                   <Route path={`${slugs.daily_exam_details}:slug`}><DailyExamDetails /></Route>
// //                   <Route path={`${slugs.daily_result_details}:slug`}><DailyResultDetails /></Route>
// //                   <Route path={`${slugs.demo_exam_details}:slug`}><DemoExamDetails /></Route>
// //                   <Route path={`${slugs.exam_details}:slug`}><ExamDetails /></Route>
// //                   <Route path={`${slugs.result_details}:slug`}><ResultDetails /></Route>
// //                   <Route path={`${slugs.student_details}:slug`}><StudentDetails /></Route>
// //                   <Route path={`${slugs.batch_details}:slug`}><BatchDetails /></Route>

// //                   <Route path={slugs.new_book}><NewBook /></Route>
// //                   <Route path={slugs.new_chapter_wise_exam}><NewChapter /></Route>
// //                   <Route path={slugs.new_daily_exam}><NewDailyExam /></Route>
// //                   <Route path={slugs.new_demo_exam}><NewDemoExam /></Route>
// //                   <Route path={slugs.new_exam}><NewExam /></Route>
// //                   <Route path={slugs.new_student}><NewStudent /></Route>
// //                   <Route path={slugs.new_batch}><NewBatch /></Route>

// //                   <Route path={slugs.exam}><AllPaidExamList /></Route>
// //                   <Route path={slugs.demo_exam}><AllDemoExamList /></Route>
// //                   <Route path={slugs.chapter_wise_exam}><AllChpaterExamList /></Route>
// //                   <Route path={slugs.daily_exam}><AllDailyExamList /></Route>

// //                   <Route path={`${slugs.start_exam}/:slug`}><StartExam /></Route>
// //                   <Route path={`${slugs.start_demo_exam}/:slug`}><StartDemoExam /></Route>
// //                   <Route path={`${slugs.start_daily_exam}/:slug`}><StartDailyExam /></Route>
// //                   <Route path={`${slugs.start_chapter_wise_exam}/:slug`}><StartChapterExam /></Route>

// //                   <Route path={`${slugs.all_chapter_results}/:slug`}><ExamResultDetailsAdmin /></Route>
// //                   <Route path={`${slugs.result_admin_view}/:slug`}><ExamResultDetailsAdmin /></Route>
// //                   <Route path={`${slugs.chapter_result_admin_view}/:slug`}><ChapterExamResultDetailsAdmin /></Route>
// //                   <Route path={slugs.exam_result}><ExamResult /></Route>
// //                   <Route path={slugs.daily_exam_result}><DailyExamResult /></Route>

// //                   <Route path={slugs.chapter_exam_result}><ChapterExamResult /></Route>
// //                   <Route path={slugs.chapter_wise_exam_result_list}><ChapterExamAllResult /></Route>

// //                   <Route path={slugs.exam_result_list}><ExamAllResult /></Route>
// //                   <Route path={slugs.daily_exam_result_list}><DailyExamAllResult /></Route>

// //                   <Route path="*"><NotFound /></Route>
// //                 </Switch>
// //               }
// //               {Login.userRole != "author" &&
// //                 <Switch>
// //                   <Route exact path="/"><AllPaidExamList /></Route>
// //                   <Route exact path="/profile"><Profile /></Route>

// //                   <Route path={slugs.exam}><AllPaidExamList /></Route>
// //                   <Route path={slugs.demo_exam}><AllDemoExamList /></Route>
// //                   <Route path={slugs.chapter_wise_exam}><AllChpaterExamList /></Route>
// //                   <Route path={slugs.daily_exam}><AllDailyExamList /></Route>

// //                   <Route path={`${slugs.start_exam}/:slug`}><StartExam /></Route>
// //                   <Route path={`${slugs.start_demo_exam}/:slug`}><StartDemoExam /></Route>
// //                   <Route path={`${slugs.start_daily_exam}/:slug`}><StartDailyExam /></Route>
// //                   <Route path={`${slugs.start_chapter_wise_exam}/:slug`}><StartChapterExam /></Route>

// //                   <Route path={slugs.exam_result}><ExamResult /></Route>
// //                   <Route path={slugs.daily_exam_result}><DailyExamResult /></Route>

// //                   <Route path={slugs.exam_result_list}><ExamAllResult /></Route>
// //                   <Route path={slugs.daily_exam_result_list}><DailyExamAllResult /></Route>

// //                   <Route path={slugs.chapter_exam_result}><ChapterExamResult /></Route>
// //                   <Route path={slugs.chapter_wise_exam_result_list}><ChapterExamAllResult /></Route>

// //                   <Route path="*"><NotFound /></Route>
// //                 </Switch>
// //               }
// //             </div>
// //           </div>
// //         </div>
// //       }

// //     </div >
// //   )
// // }


// import logo from './logo.svg';
// import './App.css';
// import React, { useEffect } from 'react';
// import {
//   BrowserRouter as Router,
//   Switch,
//   Route,
//   Link,
//   Redirect,
//   useLocation
// } from "react-router-dom";
// import store from './redux/store';

// import { getUser } from './redux/action';
// import { Provider, useDispatch, useSelector } from 'react-redux';
// import Header from './Path/Header';
// import NotFound from './Path/NotFound';
// import SideBar from './Path/SideBar';

// import AllBooks from './Path/Admin/Book';
// import NewBook from './Path/Admin/Book/BookAddNew';
// import BookDetails from './Path/Admin/Book/BookDetails';
// import { slugs } from './Constant/slugs';
// import AllDemoExam from './Path/Admin/DemoExam';
// import AllExam from './Path/Admin/Exam';
// import AllResults from './Path/Admin/Results';
// import DemoExamDetails from './Path/Admin/DemoExam/DemoExamDetails';
// import ExamDetails from './Path/Admin/Exam/ExamDetails';
// import ResultDetails from './Path/Admin/Results/ResultsDetails';
// import NewDemoExam from './Path/Admin/DemoExam/DemoExamAddNew';
// import NewExam from './Path/Admin/Exam/ExamAddNew';
// import AllStudents from './Path/Admin/Students';
// import StudentDetails from './Path/Admin/Students/StudentDetails';
// import NewStudent from './Path/Admin/Students/StudentAddNew';
// import AllPaidExamList from './Path/Student/Exam';
// import AllDemoExamList from './Path/Student/DemoExam';
// import LoginScreen from './Path/SignIn';
// import StartExam from './Path/Student/StartExam';
// import StartDemoExam from './Path/Student/StartDemoExam';
// import ExamResult from './Path/Student/ExamResult';
// import ExamAllResult from './Path/Student/AllResults';
// import Profile from './Path/Profile';
// import ExamResultDetailsAdmin from './Path/Admin/Results/ResultsDetails';
// import TeacherDetails from './Path/Admin/Teacher/TeacherDetails';
// import NewTeacher from './Path/Admin/Teacher/TeacherAddNew';
// import HomeScreenLogin from './Path/Login/HomeScreen';
// import ClassRoom from './Path/Login/ClassRoom';
// import StartClassExam from './Path/Login/ClassRoom/ClassExam';
// import AllMyExamList from './Path/Student/Exam';
// import AllTeacher from './Path/Admin/Teacher';
// import StartMyExam from './Path/Student/StartExam';
// import AllBatch from './Path/Admin/Batch';
// import AllChapter from './Path/Admin/Chapter';
// import BatchDetails from './Path/Admin/Batch/BatchDetails';
// import ChapterDetails from './Path/Admin/Chapter/ChapterDetails';
// import NewBatch from './Path/Admin/Batch/BatchAddNew';
// import NewChapter from './Path/Admin/Chapter/ChapterAddNew';


// export default class App extends React.Component {
//   render() {
//     return (
//       <Provider store={store}>
//         <Router>
//           <AppMain />
//         </Router>
//       </Provider>
//     );
//   }
// }

// function AppMain() {
//   const [loginState, setLoginState] = React.useState(true)
//   const dispatch = useDispatch()
//   const myState = useSelector((state) => state.LocationState);
//   const Login = useSelector((state) => state.LoginState);
//   const location = useLocation()

//   const [menuExpand, setMenuExpand] = React.useState(false)
//   useEffect(() => {
//     dispatch(getUser())
//     setLoginState(Login.isLoading)
//   }, [])
//   return (
//     <div className="overflow-x-hidden" >
//       {Login.token == null ?
//         <>
//           <Header setMenuExpand={setMenuExpand} menuExpand={menuExpand} />
//           <Switch>
//             <Route exact path="/"><HomeScreenLogin /></Route>
//             <Route exact path="/login"><LoginScreen /></Route>
//             <Route exact path={slugs.demo_exam}><AllDemoExamList /></Route>
//             <Route exact path={`${slugs.start_demo_exam}/:id`}><StartDemoExam /></Route>
//             <Route exact path={slugs.classroom}><ClassRoom /></Route>
//             <Route exact path={`${slugs.start_class_exam}/:id`}><StartClassExam /></Route>
//             <Route path="*"><NotFound /></Route>
//           </Switch>
//         </>
//         :
//         <div className="row" >
//           {!location.pathname.includes("start") &&
//             <div className="sidebar-width-expand" style={{ borderRight: "3px solid #e4e4e4" }} >
//               <div className={"sidebar-design"}>
//                 <div style={{ backgroundColor: "#fff" }} >
//                   <SideBar menuExpand={menuExpand} />
//                 </div>
//               </div>
//             </div>
//           }

//           {menuExpand &&
//             <div className="sidebar-small-screen ">
//               <div className={"sidebar-design"} >
//                 <SideBar setMenuExpand={setMenuExpand} />
//               </div>
//             </div>
//           }

//           <div className={!location.pathname.includes("start") ? "main-width-expand" : "main-width-full"} style={{ backgroundColor: "#f3f3f3" }}>
//             <Header setMenuExpand={setMenuExpand} menuExpand={menuExpand} />
//             <div style={{}}>
//               {(Login.userRole == "author" || Login.userRole == "teacher") &&
//                 <Switch>
//                   <Route exact path="/"><AllExam /></Route>
//                   <Route exact path="/profile"><Profile /></Route>
//                   <Route path={slugs.all_books}><AllBooks /></Route>
//                   <Route path={slugs.all_demo_exam}><AllDemoExam /></Route>
//                   <Route path={`${slugs.all_exam}/:id`}><AllExam /></Route>
//                   <Route path={slugs.all_results}><AllResults /></Route>
//                   <Route path={slugs.all_students}><AllStudents /></Route>
//                   <Route path={slugs.all_teachers}><AllTeacher /></Route>
//                   <Route path={slugs.all_batch}><AllBatch /></Route>
//                   <Route path={slugs.all_chapter}><AllChapter /></Route>

//                   <Route path={`${slugs.books_details}:slug`}><BookDetails /></Route>
//                   <Route path={`${slugs.demo_exam_details}:slug`}><DemoExamDetails /></Route>
//                   <Route path={`${slugs.exam_details}:slug`}><ExamDetails /></Route>
//                   <Route path={`${slugs.result_details}:slug`}><ResultDetails /></Route>
//                   <Route path={`${slugs.student_details}:slug`}><StudentDetails /></Route>
//                   <Route path={`${slugs.teacher_details}:slug`}><TeacherDetails /></Route>
//                   <Route path={`${slugs.batch_details}:slug`}><BatchDetails /></Route>
//                   <Route path={`${slugs.chapter_details}:slug`}><ChapterDetails /></Route>

//                   <Route path={slugs.new_book}><NewBook /></Route>
//                   <Route path={slugs.new_demo_exam}><NewDemoExam /></Route>
//                   <Route path={`${slugs.new_exam}/:id`}><NewExam /></Route>
//                   <Route path={slugs.new_student}><NewStudent /></Route>
//                   <Route path={slugs.new_teacher}><NewTeacher /></Route>
//                   <Route path={slugs.new_batch}><NewBatch /></Route>
//                   <Route path={slugs.new_chapter}><NewChapter /></Route>
                  
//                   <Route path={`${slugs.exam}`}><AllMyExamList /></Route>
//                   <Route path={slugs.demo_exam}><AllDemoExamList /></Route>

//                   <Route path={`${slugs.start_exam}/:slug`}><StartMyExam /></Route>
//                   <Route path={`${slugs.start_demo_exam}/:slug`}><StartDemoExam /></Route>

//                   <Route path={`${slugs.result_admin_view}/:slug`}><ExamResultDetailsAdmin /></Route>
//                   <Route path={slugs.exam_result}><ExamResult /></Route>

//                   <Route path={slugs.exam_result_list}><ExamAllResult /></Route>
//                   <Route exact path={slugs.classroom}><ClassRoom /></Route>
//                   <Route exact path={`${slugs.start_class_exam}/:id`}><StartClassExam /></Route>

//                   <Route path="*"><NotFound /></Route>
//                 </Switch>
//               }

//               {(Login.userRole == "student" || Login.userRole == "subscriber") &&
//                 <Switch>
//                   <Route exact path="/"><ClassRoom /></Route>
//                   <Route exact path="/profile"><Profile /></Route>

//                   <Route path={slugs.exam}><AllPaidExamList /></Route>
//                   <Route path={slugs.demo_exam}><AllDemoExamList /></Route>

//                   <Route path={`${slugs.start_exam}/:slug`}><StartExam /></Route>
//                   <Route path={`${slugs.start_demo_exam}/:slug`}><StartDemoExam /></Route>

//                   <Route path={slugs.exam_result}><ExamResult /></Route>

//                   <Route path={slugs.exam_result_list}><ExamAllResult /></Route>

//                   <Route exact path={slugs.classroom}><ClassRoom /></Route>
//                   <Route exact path={`${slugs.start_class_exam}/:id`}><StartClassExam /></Route>

//                   <Route path="*"><NotFound /></Route>
//                 </Switch>
//               }
//             </div>
//           </div>
//         </div>
//       }

//     </div >
//   )
// }


import logo from './logo.svg';
import './App.css';
import React, { useEffect } from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  Redirect,
  useLocation
} from "react-router-dom";
import store from './redux/store';

import { getUser } from './redux/action';
import { Provider, useDispatch, useSelector } from 'react-redux';
import Header from './Path/Header';
import NotFound from './Path/NotFound';
import SideBar from './Path/SideBar';

import AllBooks from './Path/Admin/Book';
import NewBook from './Path/Admin/Book/BookAddNew';
import BookDetails from './Path/Admin/Book/BookDetails';
import { slugs } from './Constant/slugs';
import AllDemoExam from './Path/Admin/DemoExam';
import AllExam from './Path/Admin/Exam';
import AllResults from './Path/Admin/Results';
import DemoExamDetails from './Path/Admin/DemoExam/DemoExamDetails';
import ExamDetails from './Path/Admin/Exam/ExamDetails';
import ResultDetails from './Path/Admin/Results/ResultsDetails';
import NewDemoExam from './Path/Admin/DemoExam/DemoExamAddNew';
import NewExam from './Path/Admin/Exam/ExamAddNew';
import AllStudents from './Path/Admin/Students';
import StudentDetails from './Path/Admin/Students/StudentDetails';
import NewStudent from './Path/Admin/Students/StudentAddNew';
import AllPaidExamList from './Path/Student/Exam';
import AllDemoExamList from './Path/Student/DemoExam';
import LoginScreen from './Path/SignIn';
import StartExam from './Path/Student/StartExam';
import StartDemoExam from './Path/Student/StartDemoExam';
import ExamResult from './Path/Student/ExamResult';
import ExamAllResult from './Path/Student/AllResults';
import Profile from './Path/Profile';
import ExamResultDetailsAdmin from './Path/Admin/Results/ResultsDetails';
import TeacherDetails from './Path/Admin/Teacher/TeacherDetails';
import NewTeacher from './Path/Admin/Teacher/TeacherAddNew';
import HomeScreenLogin from './Path/Login/HomeScreen';
import ClassRoom from './Path/Login/ClassRoom';
import StartClassExam from './Path/Login/ClassRoom/ClassExam';
import AllMyExamList from './Path/Student/Exam';
import AllTeacher from './Path/Admin/Teacher';
import StartMyExam from './Path/Student/StartExam';
import AllBatch from './Path/Admin/Batch';
import AllChapter from './Path/Admin/Chapter';
import BatchDetails from './Path/Admin/Batch/BatchDetails';
import ChapterDetails from './Path/Admin/Chapter/ChapterDetails';
import NewBatch from './Path/Admin/Batch/BatchAddNew';
import NewChapter from './Path/Admin/Chapter/ChapterAddNew';


export default class App extends React.Component {
  render() {
    return (
      <Provider store={store}>
        <Router>
          <AppMain />
        </Router>
      </Provider>
    );
  }
}

function AppMain() {
  const [loginState, setLoginState] = React.useState(true)
  const dispatch = useDispatch()
  const myState = useSelector((state) => state.LocationState);
  const Login = useSelector((state) => state.LoginState);
  const location = useLocation()

  const [menuExpand, setMenuExpand] = React.useState(false)
  useEffect(() => {
    dispatch(getUser())
    setLoginState(Login.isLoading)
  }, [])
  return (
    <div className="overflow-x-hidden" >
      {Login.token == null ?
        <>
          <Header setMenuExpand={setMenuExpand} menuExpand={menuExpand} />
          <Switch>
            <Route exact path="/"><HomeScreenLogin /></Route>
            <Route exact path="/login"><LoginScreen /></Route>
            <Route exact path={slugs.demo_exam}><AllDemoExamList /></Route>
            <Route exact path={`${slugs.start_demo_exam}/:id`}><StartDemoExam /></Route>
            <Route exact path={slugs.classroom}><ClassRoom /></Route>
            <Route exact path={`${slugs.start_class_exam}/:id`}><StartClassExam /></Route>
            <Route path="*"><NotFound /></Route>
          </Switch>
        </>
        :
        <div className="row" >
          {!location.pathname.includes("start") &&
            <div className="sidebar-width-expand" style={{ borderRight: "3px solid #e4e4e4" }} >
              <div className={"sidebar-design"}>
                <div style={{ backgroundColor: "#fff" }} >
                  <SideBar menuExpand={menuExpand} />
                </div>
              </div>
            </div>
          }

          {menuExpand &&
            <div className="sidebar-small-screen ">
              <div className={"sidebar-design"} >
                <SideBar setMenuExpand={setMenuExpand} />
              </div>
            </div>
          }

          <div className={!location.pathname.includes("start") ? "main-width-expand" : "main-width-full"} style={{ backgroundColor: "#f3f3f3" }}>
            <Header setMenuExpand={setMenuExpand} menuExpand={menuExpand} />
            <div style={{}}>
              {(Login.userRole == "author" || Login.userRole == "teacher") &&
                <Switch>
                  <Route exact path="/"><AllExam /></Route>
                  <Route exact path="/profile"><Profile /></Route>
                  <Route path={slugs.all_books}><AllBooks /></Route>
                  <Route path={slugs.all_demo_exam}><AllDemoExam /></Route>
                  <Route path={`${slugs.all_exam}/:id`}><AllExam /></Route>
                  <Route path={slugs.all_results}><AllResults /></Route>
                  <Route path={slugs.all_students}><AllStudents /></Route>
                  <Route path={slugs.all_teachers}><AllTeacher /></Route>
                  <Route path={slugs.all_batch}><AllBatch /></Route>
                  <Route path={slugs.all_chapter}><AllChapter /></Route>

                  <Route path={`${slugs.books_details}:slug`}><BookDetails /></Route>
                  <Route path={`${slugs.demo_exam_details}:slug`}><DemoExamDetails /></Route>
                  <Route path={`${slugs.exam_details}:slug`}><ExamDetails /></Route>
                  <Route path={`${slugs.result_details}:slug`}><ResultDetails /></Route>
                  <Route path={`${slugs.student_details}:slug`}><StudentDetails /></Route>
                  <Route path={`${slugs.teacher_details}:slug`}><TeacherDetails /></Route>
                  <Route path={`${slugs.batch_details}:slug`}><BatchDetails /></Route>
                  <Route path={`${slugs.chapter_details}:slug`}><ChapterDetails /></Route>

                  <Route path={slugs.new_book}><NewBook /></Route>
                  <Route path={slugs.new_demo_exam}><NewDemoExam /></Route>
                  <Route path={`${slugs.new_exam}/:id`}><NewExam /></Route>
                  <Route path={slugs.new_student}><NewStudent /></Route>
                  <Route path={slugs.new_teacher}><NewTeacher /></Route>
                  <Route path={slugs.new_batch}><NewBatch /></Route>
                  <Route path={slugs.new_chapter}><NewChapter /></Route>
                  
                  <Route path={`${slugs.exam}`}><AllMyExamList /></Route>
                  <Route path={slugs.demo_exam}><AllDemoExamList /></Route>

                  <Route path={`${slugs.start_exam}/:slug`}><StartMyExam /></Route>
                  <Route path={`${slugs.start_demo_exam}/:slug`}><StartDemoExam /></Route>

                  <Route path={`${slugs.result_admin_view}/:slug`}><ExamResultDetailsAdmin /></Route>
                  <Route path={slugs.exam_result}><ExamResult /></Route>

                  <Route path={slugs.exam_result_list}><ExamAllResult /></Route>
                  <Route exact path={slugs.classroom}><ClassRoom /></Route>
                  <Route exact path={`${slugs.start_class_exam}/:id`}><StartClassExam /></Route>

                  <Route path="*"><NotFound /></Route>
                </Switch>
              }

              {(Login.userRole == "student" || Login.userRole == "subscriber") &&
                <Switch>
                  <Route exact path="/"><ClassRoom /></Route>
                  <Route exact path="/profile"><Profile /></Route>

                  <Route path={slugs.exam}><AllPaidExamList /></Route>
                  <Route path={slugs.demo_exam}><AllDemoExamList /></Route>

                  <Route path={`${slugs.start_exam}/:slug`}><StartExam /></Route>
                  <Route path={`${slugs.start_demo_exam}/:slug`}><StartDemoExam /></Route>

                  <Route path={slugs.exam_result}><ExamResult /></Route>

                  <Route path={slugs.exam_result_list}><ExamAllResult /></Route>

                  <Route exact path={slugs.classroom}><ClassRoom /></Route>
                  <Route exact path={`${slugs.start_class_exam}/:id`}><StartClassExam /></Route>

                  <Route path="*"><NotFound /></Route>
                </Switch>
              }
            </div>
          </div>
        </div>
      }

    </div >
  )
}
